<template>
  <div>
    <h3>Longitudinal Waves</h3>
    <p>
      Waves in which the displacement of the oscillating particle is along the direction
      of wave propagation is called Longitudinal Wave. Example: Sound Wave
    </p>
    <p>The following animation from <a href="https://upload.wikimedia.org/wikipedia/commons/6/62/Onde_compression_impulsion_1d_30_petit.gif">Wikipedia</a> shows a Longitudinal Wave</p>
    <h3>Transverse Wave</h3>
    <p>
      Waves in which the oscillating particle's displacement is perpendicular to the
      direction of wave propagation is called a transverse wave. Example:Wave on a String.
    </p>
    <h3 id="mgk">
      MagicGraph | Longitudinal and Transverse Waves
    </h3>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Longitudinal & Transverse Waves';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Waves',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
