// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeDash
} from '../../../common/edliy_utils-integral';
const Boxes = {
box1: function () {
          var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-3, 13, 17, -7],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false},  showCopyright:false, showNavigation:false});
          brd1.options.layer['line'] =2;
          brd1.options.layer['polygon'] =2;
          brd1.options.layer['image'] =8;
          brd1.options.board.minimizeReflow = 'none';
          brd1.options.text.highlight =false;
          brd1.options.text.fixed =true;
          brd1.options.text.highlight =false;
          brd1.options.text.cssStyle='fontFamily:Oswald',
          brd1.options.image.highlight =false;
          brd1.options.polygon.highlight =false;
          brd1.options.line.highlight =false;
          brd1.options.point.highlight =false;
          brd1.options.curve.highlight =false;
          brd1.options.slider.highlight =false;
          brd1.options.point.showInfoBox =false;
          makeResponsive(brd1);
          placeTitle(brd1, 'Longitudinal and Transverse Wave', '');
          //var title1 = brd1.create('text', [5, 12, '<b> Longitudinal and Transverse Waves <b>'],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

          var title2 = brd1.create('text', [5, 9, 'Y = A cos(&omega;t - k x)'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);

          var txt1 = brd1.create('text', [4.75, 9, 'Transverse Wave:'],{fixed: true, anchorX: 'right', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
          brd1.create('text', [4.75, 3, 'Longitudinal Wave:'],{fixed: true, anchorX: 'right', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);
          brd1.create('text', [5, 3, 'X = A cos(&omega;t - k x)'],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}},);

          //SLiders
//          var amplitud = brd1.create('slider',[[10,11],[14,11],[0.0,2,5]], {visible: false ,face:'square', size:4, strokeWidth: 2, name:'A', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//          var frec = brd1.create('slider',[[10,10],[14,10],[0,0.1,0.2]], {visible: false ,face:'square', size:4, strokeWidth: 2, name:'f', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
          var amplitud = 2;
          var frec = 0.1;
          var time1 = brd1.create('slider',[[2,-5],[12,-5],[0,0,40]], {visible: false, snapToGrid: false ,face:'square', size:6, strokeWidth: 3, name:'t', strokeColor:'black', fillColor: 'grey',  label:{fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

          var axis1 = brd1.create('line', [ [0,0] , [2,0] ], {fixed: true, strokeColor: 'black'});
          var axis2 = brd1.create('line', [ [0,6] , [2,6] ], {fixed: true, strokeColor: 'black'});

          //Graph
          var longitudinal = brd1.create('functiongraph',
                                 [function(x){ return amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - x));}, -10, 10], {highlight:false, visible: false, dash: 1, strokeColor: 'red'}
          );

          //Graph
          var transverse = brd1.create('functiongraph',
                                 [function(x){ return 6+ amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - x ));}, 0, 20], {highlight:false, dash: 0, strokeColor: 'red', strokeWidth:2}
          );


          //Functions

          function transvP(x){return amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - x ))}

          var p1 = brd1.create('point', [function(){return amplitud.valueOf()*0.1-(amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() )))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p11 = brd1.create('point', [function(){return amplitud.valueOf()*0.1-(amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() )))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p1, p11], {fixed: true});

          var p2 = brd1.create('point', [function(){return p1.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p1.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p22 = brd1.create('point', [function(){return p1.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p1.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p2, p22], {fixed: true});

          var p3 = brd1.create('point', [function(){return p2.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p2.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p33 = brd1.create('point', [function(){return p2.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p2.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p3, p33], {fixed: true});

          var p4 = brd1.create('point', [function(){return p3.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p3.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p44 = brd1.create('point', [function(){return p3.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p3.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p4, p44], {fixed: true});

          var p5 = brd1.create('point', [function(){return p4.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p4.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p55 = brd1.create('point', [function(){return p4.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p4.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p5, p55], {fixed: true});

          var p6 = brd1.create('point', [function(){return p5.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p5.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p66 = brd1.create('point', [function(){return p5.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p5.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p6, p66], {fixed: true});

          var p7 = brd1.create('point', [function(){return p6.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p6.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p77 = brd1.create('point', [function(){return p6.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p6.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p7, p77], {fixed: true});

          var p8 = brd1.create('point', [function(){return p7.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p7.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p88 = brd1.create('point', [function(){return p7.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p7.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p8, p88], {fixed: true});

          var p9 = brd1.create('point', [function(){return p8.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p8.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p99 = brd1.create('point', [function(){return p8.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p8.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p9, p99], {fixed: true});

          var p10 = brd1.create('point', [function(){return p9.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p9.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p100 = brd1.create('point', [function(){return p9.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p9.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p10, p100], {fixed: true});
          //FALTA UNO NOSE
          var p11_ = brd1.create('point', [function(){return p10.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p10.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1111_ = brd1.create('point', [function(){return p10.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p10.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p11_, p1111_], {fixed: true});

          var p12_ = brd1.create('point', [function(){return p11_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p11_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1212_ = brd1.create('point', [function(){return p11_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p11_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p12_, p1212_], {fixed: true});

          var p13_ = brd1.create('point', [function(){return p12_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p12_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1313_ = brd1.create('point', [function(){return p12_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p12_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p13_, p1313_], {fixed: true});

          var p14_ = brd1.create('point', [function(){return p13_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p13_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1414_ = brd1.create('point', [function(){return p13_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p13_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p14_, p1414_], {fixed: true});

          var p15_ = brd1.create('point', [function(){return p14_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p14_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1515_ = brd1.create('point', [function(){return p14_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p14_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p15_, p1515_], {fixed: true});

          var p16_ = brd1.create('point', [function(){return p15_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p15_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1616_ = brd1.create('point', [function(){return p15_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p15_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p16_, p1616_], {fixed: true});

          var p17_ = brd1.create('point', [function(){return p16_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p16_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1717_ = brd1.create('point', [function(){return p16_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p16_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p17_, p1717_], {fixed: true});

          var p18_ = brd1.create('point', [function(){return p17_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p17_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1818_ = brd1.create('point', [function(){return p17_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p17_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p18_, p1818_], {fixed: true});

          var p19_ = brd1.create('point', [function(){return p18_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p18_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p1919_ = brd1.create('point', [function(){return p18_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p18_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p19_, p1919_], {fixed: true});

          var p20_ = brd1.create('point', [function(){return p19_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p19_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2020_ = brd1.create('point', [function(){return p19_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p19_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p20_, p2020_], {fixed: true});
          ////////////
          var p21_ = brd1.create('point', [function(){return p20_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p20_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2121_ = brd1.create('point', [function(){return p20_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p20_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p21_, p2121_], {fixed: true});

          var p22_ = brd1.create('point', [function(){return p21_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p21_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2222_ = brd1.create('point', [function(){return p21_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p21_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p22_, p2222_], {fixed: true});

          var p23_ = brd1.create('point', [function(){return p22_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p22_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2323_ = brd1.create('point', [function(){return p22_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p22_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p23_, p2323_], {fixed: true});

          var p24_ = brd1.create('point', [function(){return p23_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p23_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2424_ = brd1.create('point', [function(){return p23_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p23_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p24_, p2424_], {fixed: true});

          var p25_ = brd1.create('point', [function(){return p24_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p24_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2525_ = brd1.create('point', [function(){return p24_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p24_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p25_, p2525_], {fixed: true});

          var p26_ = brd1.create('point', [function(){return p25_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p25_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2626_ = brd1.create('point', [function(){return p25_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p25_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p26_, p2626_], {fixed: true});

          var p27_ = brd1.create('point', [function(){return p26_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p26_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2727_ = brd1.create('point', [function(){return p26_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p26_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p27_, p2727_], {fixed: true});

          var p28_ = brd1.create('point', [function(){return p27_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p27_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2828_ = brd1.create('point', [function(){return p27_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p27_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p28_, p2828_], {fixed: true});

          var p29_ = brd1.create('point', [function(){return p28_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p28_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p2929_ = brd1.create('point', [function(){return p28_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p28_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p29_, p2929_], {fixed: true});

          var p30_ = brd1.create('point', [function(){return p29_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p29_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3030_ = brd1.create('point', [function(){return p29_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p29_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p30_, p3030_], {fixed: true});
          ///////////

          var p31_ = brd1.create('point', [function(){return p30_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p30_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3131_ = brd1.create('point', [function(){return p30_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p30_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p31_, p3131_], {fixed: true});

          var p32_ = brd1.create('point', [function(){return p31_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p31_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3232_ = brd1.create('point', [function(){return p31_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p31_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p32_, p3232_], {fixed: true});

          var p33_ = brd1.create('point', [function(){return p32_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p32_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3333_ = brd1.create('point', [function(){return p32_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p32_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p33_, p3333_], {fixed: true});

          var p34_ = brd1.create('point', [function(){return p33_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p33_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3434_ = brd1.create('point', [function(){return p33_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p33_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p34_, p3434_], {fixed: true});

          var p35_ = brd1.create('point', [function(){return p34_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p34_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3535_ = brd1.create('point', [function(){return p34_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p34_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p35_, p3535_], {fixed: true});

          var p36_ = brd1.create('point', [function(){return p35_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p35_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3636_ = brd1.create('point', [function(){return p35_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p35_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p36_, p3636_], {fixed: true});

          var p37_ = brd1.create('point', [function(){return p36_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p36_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3737_ = brd1.create('point', [function(){return p36_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p36_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p37_, p3737_], {fixed: true});

          var p38_ = brd1.create('point', [function(){return p37_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p37_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3838_ = brd1.create('point', [function(){return p37_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p37_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p38_, p3838_], {fixed: true});

          var p39_ = brd1.create('point', [function(){return p38_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p38_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p3939_ = brd1.create('point', [function(){return p38_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p38_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p39_, p3939_], {fixed: true});

          var p40_ = brd1.create('point', [function(){return p39_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p39_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4040_ = brd1.create('point', [function(){return p39_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p39_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p40_, p4040_], {fixed: true});

          /////////

          var p41_ = brd1.create('point', [function(){return p40_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p40_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4141_ = brd1.create('point', [function(){return p40_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p40_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p41_, p4141_], {fixed: true});

          var p42_ = brd1.create('point', [function(){return p41_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p41_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4242_ = brd1.create('point', [function(){return p41_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p41_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p42_, p4242_], {fixed: true});

          var p43_ = brd1.create('point', [function(){return p42_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p42_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4343_ = brd1.create('point', [function(){return p42_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p42_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p43_, p4343_], {fixed: true});

          var p44_ = brd1.create('point', [function(){return p43_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p43_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4444_ = brd1.create('point', [function(){return p43_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p43_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p44_, p4444_], {fixed: true});

          var p45_ = brd1.create('point', [function(){return p44_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p44_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4545_ = brd1.create('point', [function(){return p44_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p44_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p45_, p4545_], {fixed: true});

          var p46_ = brd1.create('point', [function(){return p45_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p45_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4646_ = brd1.create('point', [function(){return p45_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p45_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p46_, p4646_], {fixed: true});

          var p47_ = brd1.create('point', [function(){return p46_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p46_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4747_ = brd1.create('point', [function(){return p46_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p46_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p47_, p4747_], {fixed: true});

          var p48_ = brd1.create('point', [function(){return p47_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p47_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4848_ = brd1.create('point', [function(){return p47_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p47_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p48_, p4848_], {fixed: true});

          var p49_ = brd1.create('point', [function(){return p48_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p48_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p4949_ = brd1.create('point', [function(){return p48_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p48_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p49_, p4949_], {fixed: true});

          var p50_ = brd1.create('point', [function(){return p49_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p49_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5050_ = brd1.create('point', [function(){return p49_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p49_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p50_, p5050_], {fixed: true});

          //////

          var p51_ = brd1.create('point', [function(){return p50_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p50_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5151_ = brd1.create('point', [function(){return p50_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p50_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p51_, p5151_], {fixed: true});

          var p52_ = brd1.create('point', [function(){return p51_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p51_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5252_ = brd1.create('point', [function(){return p51_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p51_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p52_, p5252_], {fixed: true});

          var p53_ = brd1.create('point', [function(){return p52_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p52_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5353_ = brd1.create('point', [function(){return p52_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p52_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p53_, p5353_], {fixed: true});

          var p54_ = brd1.create('point', [function(){return p53_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p53_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5454_ = brd1.create('point', [function(){return p53_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p53_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p54_, p5454_], {fixed: true});

          var p55_ = brd1.create('point', [function(){return p54_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p54_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5555_ = brd1.create('point', [function(){return p54_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p54_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p55_, p5555_], {fixed: true});

          var p56_ = brd1.create('point', [function(){return p55_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p55_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5656_ = brd1.create('point', [function(){return p55_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p55_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p56_, p5656_], {fixed: true});

          var p57_ = brd1.create('point', [function(){return p56_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p56_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5757_ = brd1.create('point', [function(){return p56_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p56_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p57_, p5757_], {fixed: true});

          var p58_ = brd1.create('point', [function(){return p57_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p57_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5858_ = brd1.create('point', [function(){return p57_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p57_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p58_, p5858_], {fixed: true});

          var p59_ = brd1.create('point', [function(){return p58_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p58_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p5959_ = brd1.create('point', [function(){return p58_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p58_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p59_, p5959_], {fixed: true});

          var p60_ = brd1.create('point', [function(){return p59_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p59_.X())) ))*0.1} , -1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var p6060_ = brd1.create('point', [function(){return p59_.X() + (amplitud.valueOf()*(2-Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() - p59_.X())) ))*0.1} , 1],{face:'o' , name:'pp', size:1, strokeColor: 'black', fillColor:'blue', withLabel:false, fixed:false, visible:false, label:{fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});;
          var s1 = brd1.create('segment', [p60_, p6060_], {fixed: true});

          //Hands

          var hand1 = brd1.create('image', ['/assets/pinch.svg', [-1.3, function(){return 5.3+ amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() ))}], [1.5, 1.5]], {visible: true, fixed: true, highlight:false});
          var hand2 = brd1.create('image', ['/assets/pointing.svg', [function(){return amplitud.valueOf()*0.1-(amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() )))*0.1-1.5}, -1.3], [1.5, 1.5]], {visible: true, fixed: true, highlight:false});
          brd1.create('point', [0, function(){return 6.0+ amplitud.valueOf()*Math.cos(2*Math.PI*frec.valueOf()*(time1.Value() ))}], {visible: true, name:'', size:3, fillColor:'white', strokeColor:'black'});


          //Animation
          function start(){
              time1.startAnimation(10, 500, 50);
              playImg.setAttribute({visible: false});
              pauseImg.setAttribute({visible: true});
              //hand2.moveTo([-2, -0.6], 100);

          }

          function pause(){
              time1.stopAnimation(10, 500, 50);
              playImg.setAttribute({visible: true});
              pauseImg.setAttribute({visible: false});

          }


          //Play Button
          var playImg = brd1.create('image', ['/assets/play.svg', [6.25, -4], [1.5, 1.5]], {visible: true, fixed: true, highlight:false});
          playImg.on('up', start);
          playImg.setLabel('Play')
          playImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
          playImg.on('over', function () {this.label.setAttribute({visible:true});});
          playImg.on('out', function () {this.label.setAttribute({visible:false});});


          //Pause Button
          var pauseImg = brd1.create('image', ['/assets/pause.svg', [6.25, -4], [1.5, 1.5]], {visible: false, fixed: true});
          pauseImg.on('up', pause);
          pauseImg.setLabel('pause')
          pauseImg.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
          pauseImg.on('over', function () {this.label.setAttribute({visible:true});});
          pauseImg.on('out', function () {this.label.setAttribute({visible:false});});


          brd1.unsuspendUpdate();
  }
              }
export default Boxes;
